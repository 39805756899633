import * as R from 'ramda';
import styled, { css, keyframes } from 'styled-components';
import {
  space,
  width,
  color,
  border,
  layout,
  shadow,
  height,
  variant,
  flexbox,
  fontSize,
  position,
  typography,
} from 'styled-system';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

const pulseOpacity = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.4; }
  100% { opacity: 1; }
`;

const scrollableContainerCss3px = css`
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
`;

const scrollableContainerCss4px = css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
`;

const scrollableContainerCss6px = css`
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
`;

const StyledLink = styled.a`
  ${space}
  ${width}
  ${color}
  ${border}
  ${layout}
  ${shadow}
  ${flexbox}
  ${position}
  ${typography}

  cursor: ${({ cursor }: Object) => cursor};
  transform: ${({ transform }: Object) => transform};
  animation: ${({ animation }: Object) => animation};
  word-break: ${({ wordBreak }: Object) => wordBreak};
  transition: ${({ transition }: Object) => transition};
  background: ${({ background }: Object) => background};
  text-transform: ${({ textTransform }: Object) => textTransform};
  text-decoration: ${({ textDecoration }: Object) => textDecoration || 'none'};
`;

StyledLink.defaultProps = {
  zIndex: 'unset',
  alignItems: 'center',
  justifyContent: 'normal',
};

const Form = styled.form`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
`;

const Span = styled.span`
  ${space}
  ${color}
  ${fontSize}

  cursor: ${({ cursor }: Object) => cursor};
  word-break: ${({ wordBreak }: Object) => wordBreak};
  text-transform: ${({ textTransform }: Object) => textTransform};
  font-weight: ${({ fontWeight }: Object) => fontWeight || 'normal'};
  text-decoration: ${({ textDecoration }: Object) => textDecoration || 'none'};
`;

const ArrowUp = styled.div`
  ${space}

  cursor: pointer;

  border-left: ${({ size }: Object) => R.or(size, 5)}px solid transparent;
  border-right: ${({ size }: Object) => R.or(size, 5)}px solid transparent;
  border-bottom: ${
    ({ size, color }: Object) => `${R.or(size, 5)}px solid ${G.getTheme(R.or(color, 'colors.dark.grey'))}`
  };
`;

const ArrowDown = styled.div`
  ${space}

  cursor: pointer;
  border-left: ${({ size }: Object) => R.or(size, 5)}px solid transparent;
  border-right: ${({ size }: Object) => R.or(size, 5)}px solid transparent;
  border-top: ${
    ({ size, color }: Object) => `${R.or(size, 5)}px solid ${G.getTheme(R.or(color, 'colors.dark.grey'))}`
  };
`;

const commonStyles = {
  width: '100%',
  p: '2px 10px',
  borderRadius: 4,
  textAlign: 'center',
  border: '1px solid',
};

const getColorStyles = ({ color, borderColor, backgroundColor }: Object) => ({
  color: () => G.getTheme(color),
  borderColor: () => G.getTheme(borderColor),
  backgroundColor: () => G.getTheme(backgroundColor),
});

const Badge = styled.span`
  ${variant({
    variants: {
      default: {
        ...commonStyles,
        ...getColorStyles({
          color: 'colors.dark.darkBlue',
          borderColor: 'colors.dark.darkBlue',
          backgroundColor: 'colors.bgLightGrey',
        }),
      },
      dark: {
        ...commonStyles,
        ...getColorStyles({
          color: 'colors.light.black',
          borderColor: 'colors.light.black',
          backgroundColor: 'colors.light.lightGrey',
        }),
      },
      grey: {
        ...commonStyles,
        ...getColorStyles({
          color: 'colors.black',
          borderColor: 'colors.dark.lightGrey',
          backgroundColor: 'colors.dark.lightGrey',
        }),
      },
      red: {
        ...commonStyles,
        ...getColorStyles({
          color: 'colors.light.darkRed',
          borderColor: 'colors.dark.mainRed',
          backgroundColor: 'colors.light.lightPink',
        }),
      },
      green: {
        ...commonStyles,
        ...getColorStyles({
          color: 'colors.light.green',
          borderColor: 'colors.softGreen',
          backgroundColor: 'colors.light.teaGreen',
        }),
      },
      yellow: {
        ...commonStyles,
        ...getColorStyles({
          color: 'colors.lightBrown',
          borderColor: 'colors.dark.yellow',
          backgroundColor: 'colors.light.warningBackground',
        }),
      },
    },
  })}
`;

Badge.defaultProps = {
  variant: 'default',
};

const Iframe = styled.iframe`
  ${width}
  ${height}
`;

Iframe.defaultProps = {
  width: '100%',
  height: '100%',
  title: G.getWindowLocale('titles:file-preview', 'File Preview'),
};

export {
  Form,
  Span,
  Badge,
  Iframe,
  ArrowUp,
  ArrowDown,
  StyledLink,
  pulseOpacity,
  scrollableContainerCss3px,
  scrollableContainerCss4px,
  scrollableContainerCss6px,
};
