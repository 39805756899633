/* eslint-disable max-len */
import React from 'react';
//////////////////////////////////////////////////

const uiFalse = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#f00'}
      d='M14.911 3.98a7.958 7.958 0 0 0-2.908-2.908A7.817 7.817 0 0 0 7.992 0C6.542 0 5.205.357 3.98 1.072A7.956 7.956 0 0 0 1.072 3.98 7.817 7.817 0 0 0 0 7.992c0 1.45.357 2.786 1.072 4.01a7.958 7.958 0 0 0 2.908 2.91 7.818 7.818 0 0 0 4.012 1.071 7.81 7.81 0 0 0 4.011-1.072 7.956 7.956 0 0 0 2.908-2.908 7.818 7.818 0 0 0 1.072-4.011c0-1.45-.357-2.788-1.072-4.012zm-3.153 5.895a.64.64 0 0 1 .198.468.652.652 0 0 1-.198.479l-.936.936a.652.652 0 0 1-.479.198.64.64 0 0 1-.468-.198L7.992 9.875l-1.884 1.883a.64.64 0 0 1-.468.198.652.652 0 0 1-.479-.198l-.936-.936a.652.652 0 0 1-.198-.479.64.64 0 0 1 .198-.468L6.108 7.99 4.225 6.108a.64.64 0 0 1-.198-.468.65.65 0 0 1 .198-.479l.936-.936a.652.652 0 0 1 .479-.198.64.64 0 0 1 .468.198l1.884 1.883 1.883-1.883a.64.64 0 0 1 .468-.198.65.65 0 0 1 .479.198l.936.936a.652.652 0 0 1 .198.479.64.64 0 0 1-.198.468L9.875 7.991l1.883 1.884z'
    />
  </svg>
);

const uiTrue = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 16 16'
    width={width || 16}
    height={height || 16}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#1d9206'}
      d='M14.911 3.98a7.958 7.958 0 0 0-2.908-2.908A7.817 7.817 0 0 0 7.992 0C6.542 0 5.205.357 3.98 1.072A7.956 7.956 0 0 0 1.072 3.98 7.817 7.817 0 0 0 0 7.992c0 1.45.357 2.786 1.072 4.01a7.958 7.958 0 0 0 2.908 2.91 7.818 7.818 0 0 0 4.012 1.071 7.81 7.81 0 0 0 4.011-1.072 7.956 7.956 0 0 0 2.908-2.908 7.818 7.818 0 0 0 1.072-4.011c0-1.45-.357-2.788-1.072-4.012zm-1.738 2.794l-5.65 5.65a.651.651 0 0 1-.478.198.64.64 0 0 1-.469-.198L2.81 8.657a.636.636 0 0 1-.187-.468c0-.194.062-.354.187-.479l.947-.936a.64.64 0 0 1 .469-.198.64.64 0 0 1 .468.198l2.352 2.352L11.29 4.89a.64.64 0 0 1 .468-.198.64.64 0 0 1 .469.198l.947.936a.647.647 0 0 1 .187.479.634.634 0 0 1-.188.468z'
    />
  </svg>
);

const user = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 15 15'
    width={width || 15}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill={color || '#353535'}
      d='M7 10c-2.206 0-4-1.802-4-4.017V4.017C3 1.802 4.794 0 7 0s4 1.802 4 4.017v1.966C11 8.198 9.206 10 7 10zM7 .637a3.377 3.377 0 0 0-3.366 3.38v1.966c0 1.864 1.51 3.38 3.366 3.38a3.377 3.377 0 0 0 3.366-3.38V4.017c0-1.864-1.51-3.38-3.366-3.38zM13.2 15H1.8C.807 15 0 14.143 0 13.089V11.41c0-.625.296-1.46 1.705-1.897l1.706-.501a.334.334 0 0 1 .413.249.36.36 0 0 1-.234.439l-1.701.5C.672 10.576.672 11.204.672 11.41v1.678c0 .66.505 1.198 1.127 1.198h11.402c.621 0 1.127-.538 1.127-1.198V11.41c0-.556-.41-.964-1.22-1.211l-1.623-.482a.36.36 0 0 1-.233-.44c.05-.19.235-.3.414-.247l1.625.482C14.704 9.944 15 10.782 15 11.41v1.678C15 14.143 14.193 15 13.2 15z'
    />
  </svg>
);

const users = (color: string, width: any, height: any) => (
  <svg
    viewBox='0 0 18 15'
    width={width || 18}
    height={height || 15}
    xmlns='http://www.w3.org/2000/svg'
  >
    <g fill={color || '#930000'} >
      <path d='M5.73 4.023c0 .877.347 1.89.926 2.708.657.93 1.489 1.44 2.341 1.44s1.684-.51 2.341-1.44c.58-.819.925-1.831.925-2.708 0-1.786-1.465-3.24-3.266-3.24s-3.266 1.454-3.266 3.24zm3.267-2.677c1.488 0 2.699 1.201 2.699 2.677 0 .754-.315 1.668-.822 2.385-.54.763-1.225 1.201-1.877 1.201S7.661 7.171 7.12 6.408c-.507-.717-.822-1.63-.822-2.385 0-1.476 1.21-2.677 2.7-2.677zm2.437 6.198a.285.285 0 0 0-.322.055c-.634.627-1.366.958-2.115.958s-1.48-.331-2.115-.958a.285.285 0 0 0-.322-.055 5.673 5.673 0 0 0-2.337 2.04 5.574 5.574 0 0 0-.902 3.044v.005a.28.28 0 0 0 .093.208 8.333 8.333 0 0 0 5.583 2.135 8.333 8.333 0 0 0 5.583-2.135.28.28 0 0 0 .093-.208v-.005a5.575 5.575 0 0 0-.902-3.045 5.671 5.671 0 0 0-2.337-2.04zm-2.437 6.87a7.765 7.765 0 0 1-5.107-1.906c.022-.934.3-1.837.81-2.62a5.106 5.106 0 0 1 1.93-1.75c.709.643 1.524.982 2.367.982s1.658-.339 2.367-.982a5.106 5.106 0 0 1 1.93 1.75c.51.783.788 1.686.81 2.62a7.765 7.765 0 0 1-5.107 1.906z' />
      <path d='M17.209 7.72a4.943 4.943 0 0 0-2.037-1.777.285.285 0 0 0-.322.055c-.541.535-1.165.818-1.802.818-.346 0-.688-.082-1.017-.245a.285.285 0 0 0-.38.125.28.28 0 0 0 .127.378c.408.202.835.305 1.27.305.73 0 1.437-.29 2.053-.842a4.38 4.38 0 0 1 1.631 1.489 4.3 4.3 0 0 1 .693 2.228 6.71 6.71 0 0 1-2.416 1.336.28.28 0 1 0 .166.538 7.28 7.28 0 0 0 2.726-1.541.28.28 0 0 0 .093-.209v-.003a4.86 4.86 0 0 0-.785-2.654zM4.935 6.468a2 2 0 0 0 .889-.216.28.28 0 0 0 .128-.377.285.285 0 0 0-.38-.127 1.436 1.436 0 0 1-.637.157c-.55 0-1.129-.372-1.587-1.02-.433-.612-.702-1.391-.702-2.035A2.282 2.282 0 0 1 4.935.58c.595 0 1.16.226 1.588.636a.285.285 0 0 0 .401-.007.28.28 0 0 0-.007-.397A2.854 2.854 0 0 0 4.935.018c-1.575 0-2.856 1.27-2.856 2.832 0 .765.3 1.646.804 2.358.575.812 1.303 1.26 2.052 1.26z' />
      <path d='M2.308 11.33a6.638 6.638 0 0 1-1.74-1.087c.023-.792.26-1.558.693-2.222a4.365 4.365 0 0 1 1.626-1.484c.614.55 1.318.84 2.047.84a2.85 2.85 0 0 0 1.28-.31.28.28 0 0 0 .125-.379.285.285 0 0 0-.381-.124c-.331.166-.675.25-1.024.25-.635 0-1.257-.282-1.796-.816a.285.285 0 0 0-.322-.055A4.932 4.932 0 0 0 .784 7.716 4.846 4.846 0 0 0 0 10.364v.003c0 .08.034.155.093.209.591.531 1.26.959 1.99 1.27a.285.285 0 0 0 .373-.146.28.28 0 0 0-.148-.37zm.334.408c-.101.241.17.48.401.352a.282.282 0 0 0 .122-.352c-.091-.23-.43-.224-.523 0zm8.829-10.522c.429-.41.993-.635 1.588-.635a2.282 2.282 0 0 1 2.29 2.27c0 .643-.27 1.422-.703 2.034-.458.648-1.037 1.02-1.587 1.02a1.42 1.42 0 0 1-.636-.157.285.285 0 0 0-.38.127.28.28 0 0 0 .127.377c.29.143.59.216.89.216.748 0 1.476-.447 2.05-1.26.505-.712.805-1.593.805-2.358 0-1.561-1.28-2.832-2.856-2.832-.743 0-1.447.282-1.982.794a.28.28 0 0 0-.007.397.285.285 0 0 0 .401.007z' />
    </g>
  </svg>
);

const upload = (color: string, width: any, height: any) => (
  <svg
    fill='none'
    viewBox='0 0 18 18'
    width={width || 18}
    height={height || 18}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M16.5 11.5V12.5C16.5 13.9001 16.5 14.6002 16.2275 15.135C15.9878 15.6054 15.6054 15.9878 15.135 16.2275C14.6002 16.5 13.9001 16.5 12.5 16.5H5.5C4.09987 16.5 3.3998 16.5 2.86502 16.2275C2.39462 15.9878 2.01217 15.6054 1.77248 15.135C1.5 14.6002 1.5 13.9001 1.5 12.5V11.5M13.1667 5.66667L9 1.5M9 1.5L4.83333 5.66667M9 1.5V11.5' stroke={color || '#1E4785'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

export {
  user,
  users,
  uiTrue,
  upload,
  uiFalse,
};
