import * as R from 'ramda';
import * as P from 'plow-js';
import { createAction } from 'redux-act';
import { createSelector } from 'reselect';
import { put, call, select } from 'redux-saga/effects';
import { pure, compose, branch, withState, withHandlers, renderNothing } from 'react-recompose';
// components
import { closeModal } from '../components/modal/actions';
import { openLoader, closeLoader } from '../components/loader/actions';
import {
  checkReportFunction,
  transformSearchCriteriaBeforeFilterPost,
  transformSearchCriteriaBeforeReportPost,
} from '../components/edit-report/helpers';
// features
import { inspectionReportDefaultFields } from '../features/inspections/settings/table-settings';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// utilities
import { sendRequest } from '../utilities/http';
import endpointsMap from '../utilities/endpoints';
//////////////////////////////////////////////////

const DEFAULT_SPLITTER = 'Default';

const defaultReportFieldsMap = {
  [GC.INSPECTION_REPORT]: inspectionReportDefaultFields,
};

export const generateDefaultReport = (type: string) => ({
  type,
  prompt: false,
  searchCriteria: [],
  guid: `${type}${DEFAULT_SPLITTER}`,
  name: G.getWindowLocale('titles:default', 'Default'),
  fields: R.pathOr([], [type], defaultReportFieldsMap),
});

export const getMockReport = (name: string) => generateDefaultReport(R.head(R.split(DEFAULT_SPLITTER, name)));

export const getFilterParamsFromColumnSettings = (columnSettings: Object) => R.compose(
  R.values,
  R.mapObjIndexed(({ name, filter = { type: 'string' } }: Object, value: string) => ({
    ...filter,
    value,
    [GC.FIELD_NAME]: G.ifElse(
      G.isArray(name),
      `${G.getWindowLocale(name[0])}: ${G.getWindowLocale(name[1])}`,
      G.getWindowLocale(name),
    ),
  })),
)(columnSettings);

export const getReportActions = () => ({
  setReports: createAction('setReports'),
  selectItem: createAction('selectItem'),
  setItemList: createAction('setItemList'),
  setListType: createAction('setListType'),
  printRequest: createAction('printRequest'),
  setReportType: createAction('setReportType'),
  setUsedReport: createAction('setUsedReport'),
  getXMLRequest: createAction('getXMLRequest'),
  setListLoading: createAction('setListLoading'),
  setPageVisited: createAction('setPageVisited'),
  setFilterProps: createAction('setFilterProps'),
  setInitialState: createAction('setInitialState'),
  setReportPending: createAction('setReportPending'),
  cleanQuickFilter: createAction('cleanQuickFilter'),
  deleteItemRequest: createAction('deleteItemRequest'),
  deleteItemSuccess: createAction('deleteItemSuccess'),
  setTableTitleSort: createAction('setTableTitleSort'),
  getItemListRequest: createAction('getItemListRequest'),
  getItemListSuccess: createAction('getItemListSuccess'),
  createReportRequest: createAction('createReportRequest'),
  updateReportRequest: createAction('updateReportRequest'),
  setTableTitleFilter: createAction('setTableTitleFilter'),
  setQuickFilterParams: createAction('setQuickFilterParams'),
  printByReportRequest: createAction('printByReportRequest'),
  getXMLByReportRequest: createAction('getXMLByReportRequest'),
  setIgnorePromptStatus: createAction('setIgnorePromptStatus'),
  resetListAndPagination: createAction('resetListAndPagination'),
  exportReportDataRequest: createAction('exportReportDataRequest'),
  setInitialStateOmitReport: createAction('setInitialStateOmitReport'),
  changeDefaultReportRequest: createAction('changeDefaultReportRequest'),
  getAvailableReportsRequest: createAction('getAvailableReportsRequest'),
});

const initialState = {
  totalCount: 0,
  loading: false,
  itemList: null,
  usedReport: null,
  filterParams: {},
  listType: 'listUp',
  pageVisited: false,
  titleSortValues: {},
  reportPending: false,
  tableTitleFilters: {},
  availableReports: null,
  pagination: { limit: 20, offset: 0 },
};

export const getReportReducers = (initial: Object) => {
  const initialStateToUse = R.mergeRight(initialState, R.or(initial, {}));

  const setInitialState = () => initialStateToUse;

  const setInitialStateOmitReport = (state: Object, omit: any) => {
    const {
      usedReport,
      pageVisited,
      filterParams,
      titleSortValues,
      tableTitleFilters,
    } = state;

    const toOmit = R.pick(R.or(omit, []), state);

    return {
      ...initialStateToUse,
      ...toOmit,
      usedReport,
      pageVisited,
      filterParams,
      titleSortValues,
      tableTitleFilters,
    };
  };

  const setListLoading = (state: Object, data: boolean) => (
    P.$set('loading', data, state)
  );

  const setItemList = (state: Object, data: Array) => (
    P.$set('itemList', data, state)
  );

  const setListType = (state: Object, type: string) => (
    P.$set('listType', type, state)
  );

  const setPageVisited = (state: Object, data: boolean) => (
    P.$set('pageVisited', data, state)
  );

  const setReportPending = (state: Object) => (
    P.$set('reportPending', true, state)
  );

  const setReports = (state: Object, data: Array) => (
    P.$set('availableReports', data, state)
  );

  const resetListAndPagination = (state: Object) => (
    P.$all(
      P.$set('itemList', null),
      P.$set('pagination', initialStateToUse.pagination),
      state,
    )
  );

  const setUsedReport = (state: Object, data: Object) => (
    P.$all(
      P.$set('itemList', null),
      P.$set('usedReport', data),
      P.$set('titleSortValues', {}),
      P.$set('reportPending', false),
      P.$set('tableTitleFilters', {}),
      P.$set('pagination', initialStateToUse.pagination),
      state,
    )
  );

  const getItemListSuccess = (state: Object, { data, guids }: Object) => {
    const { itemList, pagination } = state;

    const { results, totalCount } = data;

    if (R.and(G.isNotNilAndNotEmpty(guids), R.equals(R.length(results), 1))) {
      const result = R.head(results);
      const guid = R.path([0, GC.FIELD_GUID], results);

      if (G.isNotNil(R.prop(guid, itemList))) {
        return P.$set(`itemList.${guid}`, R.mergeRight(R.prop(guid, itemList), result), state);
      }

      const remappedList = R.compose(
        R.assoc(guid, R.compose(
          R.assoc('index', 0),
          R.assoc('selected', false),
        )(result)),
        R.map((item: Object) => R.assoc('index', R.inc(R.prop('index', item)), item)),
      )(itemList);

      return P.$all(
        P.$set('itemList', remappedList),
        P.$set('totalCount', R.inc(state.totalCount)),
        P.$set('pagination.offset', R.inc(pagination.offset)),
        state,
      );
    }

    const indexAdditional = G.ifElse(
      R.isNil(itemList),
      0,
      R.length(R.values(itemList)),
    );

    const newItems = results.map((item: Object, index: number) => R.mergeRight(
      item,
      {
        selected: false,
        index: R.add(index, indexAdditional),
      },
    ));

    const list = R.mergeRight(itemList, R.indexBy(R.prop('guid'), newItems));
    const newOffset = R.add(pagination.offset, pagination.limit);

    return P.$all(
      P.$set('itemList', list),
      P.$set('pageVisited', true),
      P.$set('pagination.limit', 10),
      P.$set('totalCount', totalCount),
      P.$set(
        'pagination.offset',
        G.ifElse(
          R.gt(totalCount, newOffset),
          newOffset,
          totalCount,
        ),
      ),
      state,
    );
  };

  const getItemListSuccess2 = (state: Object, data: Object) => {
    const { itemList, pagination } = state;

    const { results, totalCount } = data;

    const list = R.concat(R.or(itemList, []), results);
    const newOffset = R.add(pagination.offset, pagination.limit);

    return P.$all(
      P.$set('itemList', list),
      P.$set('pagination.limit', 10),
      P.$set('totalCount', totalCount),
      P.$set(
        'pagination.offset',
        G.ifElse(
          R.gt(totalCount, newOffset),
          newOffset,
          totalCount,
        ),
      ),
      state,
    );
  };

  const cleanQuickFilter = (state: Object) => (
    P.$all(
      P.$set('itemList', null),
      P.$set('filterParams', {}),
      P.$set('pagination', initialState.pagination),
      state,
    )
  );

  const setQuickFilterParams = (state: Object, data: Object) => (
    P.$set('filterParams', data, state)
  );

  const deleteItemSuccess = (state: Object, data: any) => {
    if (G.isArray(data)) {
      const totalCount = R.subtract(state.totalCount, R.length(data));
      const itemList = R.omit(data, state.itemList);

      return P.$all(
        P.$set('itemList', itemList),
        P.$set('totalCount', totalCount),
        state,
      );
    }

    return P.$all(
      P.$drop(`itemList.${data}`),
      P.$set('totalCount', G.ifElse(R.equals(state.totalCount, 0), 0, R.dec(state.totalCount))),
      state,
    );
  };

  const selectItem = (state: Object, data: string) => {
    const { itemList } = state;

    if (R.equals(data, 'all')) {
      const value = R.not(R.all(
        (item: Object) => item.selected,
        R.values(itemList),
      ));

      return P.$set(
        'itemList',
        R.map(
          (item: Object) => R.assoc(
            'selected',
            value,
            item,
          ),
          itemList,
        ),
        state,
      );
    }

    return P.$toggle(`itemList.${data}.selected`, state);
  };

  const handleUpdateReportFromReportListSuccess = (state: Object, data: Object) => {
    if (G.isNilOrEmpty(data)) return state;

    const updatedGuid = G.getGuidFromObject(data);

    const usedUpdated = R.pathEq(updatedGuid, ['usedReport', GC.FIELD_GUID], state);

    if (G.isTrue(usedUpdated)) return P.$set('usedReport', data, state);

    return state;
  };

  return {
    setReports,
    selectItem,
    setItemList,
    setListType,
    setUsedReport,
    setPageVisited,
    setListLoading,
    setInitialState,
    setReportPending,
    cleanQuickFilter,
    deleteItemSuccess,
    getItemListSuccess,
    getItemListSuccess2,
    setQuickFilterParams,
    resetListAndPagination,
    setInitialStateOmitReport,
    initialState: initialStateToUse,
    setTableTitleSort: G.setTableTitleSort,
    handleUpdateReportFromReportListSuccess,
    setTableTitleFilter: G.setTableTitleFilter,
  };
};

export const getReportSelectors = (reducerName: string) => {
  const store = (state: Object) => state[reducerName];

  const makeSelectReportStatus = () => createSelector(
    store,
    (state: Object) => state.reportPending,
  );

  const makeSelectAvailableReports = () => createSelector(
    store,
    ({ availableReports }: Object) => availableReports,
  );

  const makeSelectUsedReport = () => createSelector(
    store,
    ({ usedReport }: Object) => usedReport,
  );

  const makeSelectFilterParams = () => createSelector(
    store,
    ({ filterParams }: Object) => filterParams,
  );

  const makeSelectItemList = () => createSelector(
    store,
    ({ itemList }: Object) => itemList,
  );

  const makeSelectListType = () => createSelector(
    store,
    ({ listType }: Object) => listType,
  );

  const makeSelectPagination = () => createSelector(
    store,
    ({ pagination }: Object) => pagination,
  );

  const makeSelectTotalCount = () => createSelector(
    store,
    ({ totalCount }: Object) => totalCount,
  );

  const makeSelectListLoading = () => createSelector(
    store,
    ({ loading }: Object) => loading,
  );

  const makeSelectTableTitleFilters = () => createSelector(
    store,
    ({ tableTitleFilters }: Object) => tableTitleFilters,
  );

  const makeSelectTitleSortValues = () => createSelector(
    store,
    ({ titleSortValues }: Object) => titleSortValues,
  );

  const makeSelectPageVisited = () => createSelector(
    store,
    ({ pageVisited }: Object) => pageVisited,
  );

  return {
    store,
    makeSelectItemList,
    makeSelectListType,
    makeSelectUsedReport,
    makeSelectPagination,
    makeSelectTotalCount,
    makeSelectPageVisited,
    makeSelectListLoading,
    makeSelectReportStatus,
    makeSelectFilterParams,
    makeSelectTitleSortValues,
    makeSelectAvailableReports,
    makeSelectTableTitleFilters,
  };
};

const reportTypeToEndpointMap = {
  [GC.CUSTOMER_INVOICE_REPORT]: {
    getXMLByReport: endpointsMap.customerInvoiceXml,
    printByReport: endpointsMap.cloInvoicePrintByReport,
  },
  [GC.DRIVER_PAYROLL_INVOICE_REPORT]: {
    getXMLByReport: endpointsMap.driverInvoiceXml,
    printByReport: endpointsMap.driverInvoicePrintByReport,
  },
  [GC.VENDOR_INVOICE_REPORT]: {
    printByReport: endpointsMap.telFleetVendorInvoicePrintByReport,
    getXMLByReport: endpointsMap.telFleetVendorInvoiceXmlFileByReport,
  },
};

export const getReportSagas = (reportType: string, A: actions, handleGetItemListSaga: Function, options: any) => {
  function* handleAvailableReportsRequest({ notSetUsedReport }: Object) {
    try {
      const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

      if (G.isNilOrEmpty(currentBranchGuid)) return false;

      const params = {
        reportType,
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
      };

      const res = yield call(sendRequest, 'get', endpointsMap.listReports, { params });

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const reports = G.getReportsSortedBySeqFreez(data);
        const checkedReports = checkReportFunction(reports);

        yield put(A.setReports(checkedReports));

        if (R.not(R.prop('length', reports))) {
          return yield put(A.setUsedReport(generateDefaultReport(reportType)));
        }

        if (R.not(notSetUsedReport)) {
          const defaultReport = G.findDefaultReport(checkedReports);
          const usedReport = R.or(defaultReport, generateDefaultReport(reportType));

          yield put(A.setUsedReport(usedReport));
        }
      } else {
        yield call(G.handleFailResponse, res, 'handleAvailableReportsRequest fail');
      }
    } catch (error) {
      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      yield call(G.handleException, error, 'handleAvailableReportsRequest exception');
    }
  }

  const getCreateUpdateData = (data: Object) => {
    const callback = R.path(['createUpdateReportSuccessCallback'], options);

    if (G.isFunction(callback)) return callback(data);

    return data;
  };

  function* handleCreateReportRequestSaga({ payload }: Object) {
    try {
      yield put(openLoader());

      const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));

      const options = {
        data: R.mergeRight(payload, { searchCriteria, [GC.FIELD_BRANCH_GUID]: currentBranchGuid }),
      };

      const res = yield call(sendRequest, 'post', endpointsMap.report, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const report = R.head(checkReportFunction(R.of(Array, data)));

        yield put(A.setUsedReport(getCreateUpdateData(report)));

        yield call(handleAvailableReportsRequest, { notSetUsedReport: true });
        yield call(handleGetItemListSaga, { payload: { openLoader: true } });
      } else {
        yield call(G.handleFailResponse, res, 'handleCreateReportRequestSaga fail');
      }

      yield put(closeLoader());
    } catch (error) {
      yield put(closeLoader());

      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      yield call(G.handleException, error, 'handleCreateReportRequestSaga exception');
    }
  }

  function* handleUpdateReportRequestSaga({ payload }: Object) {
    try {
      yield put(openLoader());

      const searchCriteria = transformSearchCriteriaBeforeReportPost(R.path(['searchCriteria'], payload));

      const options = {
        data: R.assoc('searchCriteria', searchCriteria, payload),
      };

      const res = yield call(sendRequest, 'put', endpointsMap.report, options);

      const { data, status } = res;

      if (G.isResponseSuccess(status)) {
        const report = R.head(checkReportFunction(R.of(Array, data)));

        yield put(A.setUsedReport(getCreateUpdateData(report)));

        yield call(handleAvailableReportsRequest, { notSetUsedReport: true });
        yield call(handleGetItemListSaga, { payload: { openLoader: true } });
      } else {
        yield call(G.handleFailResponse, res, 'handleUpdateReportRequestSaga fail');
      }

      yield put(closeLoader());
    } catch (error) {
      yield put(closeLoader());

      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      yield call(G.handleException, error, 'handleUpdateReportRequestSaga exception');
    }
  }

  function* handleChangeDefaultReportSaga({ payload }: Object) {
    try {
      yield put(openLoader());

      const res = yield call(sendRequest, 'put', endpointsMap.changeDefaultReport, { data: payload });

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        yield call(handleAvailableReportsRequest, {});
        yield call(handleGetItemListSaga, { payload: { openLoader: true } });
      } else {
        yield call(G.handleFailResponse, res, 'handleChangeDefaultReportSaga fail');
      }

      yield put(closeLoader());
    } catch (error) {
      yield put(closeLoader());

      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      yield call(G.handleException, error, 'handleChangeDefaultReportSaga exception');
    }
  }

  const getSelectors = () => G.getPropFromObject('S', options);

  function* handlePrintByReportSaga({ payload }: Object) {
    try {
      yield put(openLoader());

      const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const S = getSelectors();

      const reportParams = yield select(S.makeSelectUsedReport());
      const filterParams = yield select(S.makeSelectFilterParams());

      const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

      const reqBody = {
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        fields: G.getOrElse(reportParams, 'fields', []),
        orderFields: G.transformReferenceOrderFields(G.getOrElse(reportParams, 'orderFields', [])),
        searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
      };

      const options = {
        params: payload,
        resType: 'arraybuffer',
        data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
      };

      const endpoint = R.path([reportType, 'printByReport'], reportTypeToEndpointMap);

      const res = yield call(sendRequest, 'post', endpoint, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res, reportType);

        yield put(closeModal());
      } else {
        yield call(G.handleFailResponse, res, 'handlePrintByReportSaga fail');
      }

      yield put(closeLoader());
    } catch (error) {
      yield put(closeLoader());

      yield call(G.showToastrMessage, 'error', 'messages:error:unknown');
      yield call(G.handleException, error, 'handlePrintByReportSaga exception');
    }
  }

  function* handleGetXMLByReportSaga() {
    try {
      yield put(openLoader({ showDimmer: true }));

      const currentBranchGuid = G.getAmousCurrentBranchGuidFromWindow();

      const S = getSelectors();

      const reportParams = yield select(S.makeSelectUsedReport());
      const filterParams = yield select(S.makeSelectFilterParams());

      const requestFilterParams = transformSearchCriteriaBeforeFilterPost(filterParams);

      const reqBody = {
        [GC.FIELD_CURRENT_BRANCH]: currentBranchGuid,
        fields: G.getOrElse(reportParams, 'fields', []),
        orderFields: G.transformReferenceOrderFields(G.getOrElse(reportParams, 'orderFields', [])),
        searchCriteria: transformSearchCriteriaBeforeReportPost(G.getOrElse(reportParams, 'searchCriteria', [])),
      };

      const options = {
        resType: 'arraybuffer',
        data: G.setSearchCriteria({ filterParams: requestFilterParams, reqBody }),
      };

      const endpoint = R.path([reportType, 'getXMLByReport'], reportTypeToEndpointMap);

      const res = yield call(sendRequest, 'post', endpoint, options);

      const { status } = res;

      if (G.isResponseSuccess(status)) {
        G.saveFileFromResponse(res, `${reportType}.xml`);

        yield put(closeModal());
      } else {
        yield call(G.handleFailResponse, res, 'handleGetXMLByReportSaga fail');
      }

      yield put(closeLoader());
    } catch (error) {
      yield put(closeLoader());

      yield call(G.handleException, error, 'handleGetXMLByReportSaga exception');
    }
  }

  return {
    handlePrintByReportSaga,
    handleGetXMLByReportSaga,
    handleAvailableReportsRequest,
    handleCreateReportRequestSaga,
    handleUpdateReportRequestSaga,
    handleChangeDefaultReportSaga,
  };
};

export const reportEnhancer = compose(
  withState('originalReport', 'setOriginalReport', null),
  withState('reportFromPrompt', 'setReportFromPrompt', null),
  withHandlers({
    handleTableTitleFilter: G.handleTableTitleFilter,
    handleListRequest: ({ getItemListRequest }: Object) => () => getItemListRequest(),
    getQuickFilteredListRequest: ({ getItemListRequest, resetListAndPagination }: Object) => () => {
      resetListAndPagination();
      getItemListRequest();
    },
    handleSelectReport: (props: Object) => (reportGuid: string) => {
      const { reportList, setUsedReport, getItemListRequest } = props;

      const selectedReport = R.find(R.propEq(reportGuid, GC.FIELD_GUID), reportList);

      setUsedReport(selectedReport);
      getItemListRequest();
    },
    handleChangeReportParams: ({ setReportFromPrompt }: Object) => (data: Object) => setReportFromPrompt(data),
    handleSetUsedReport: (props: Object) => () => {
      const {
        setUsedReport,
        selectedReport,
        setPromptStatus,
        reportFromPrompt,
        setOriginalReport,
        getItemListRequest,
      } = props;

      if (R.and(
        G.isNotNilAndNotEmpty(reportFromPrompt),
        G.notEquals(selectedReport, reportFromPrompt),
      )) {
        setOriginalReport(selectedReport);
        setUsedReport(reportFromPrompt);
        getItemListRequest(true);
      }

      setPromptStatus(false);
    },
    handleCleanFilter: (props: Object) => () => {
      const { cleanQuickFilter, getItemListRequest, resetListAndPagination } = props;

      cleanQuickFilter();
      resetListAndPagination();
      getItemListRequest(true);
    },
  }),
  pure,
);

export const reportBranchEnhancer = compose(
  branch(
    ({ selectedReport, initialDataLoaded }: Object) => R.or(
      R.not(initialDataLoaded),
      G.isNilOrEmpty(selectedReport),
    ),
    renderNothing,
  ),
  pure,
);
