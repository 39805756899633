import * as R from 'ramda';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import React, { useMemo, useCallback } from 'react';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
// helpers/constants
import * as G from '../helpers';
import * as GC from '../constants';
// forms
import { renderBorderColor } from '../forms/formik/fieldset2/ui';
//////////////////////////////////////////////////

const makeDefaultStyles = ({
  width,
  height,
  hasError,
  useMaxHeight,
  valueContainerMaxHeight,
}: Object = {}) => ({
  container: (baseStyles: Object) => ({
    ...baseStyles,
    width,
  }),
  menu: (baseStyles: Object) => ({
    ...baseStyles,
    marginTop: G.ifElse(hasError, 2, 8),
  }),
  menuPortal: (baseStyles: Object) => ({
    ...baseStyles,
    zIndex: 1300,
    marginTop: 0,
  }),
  dropdownIndicator: (baseStyles: Object) => ({
    ...baseStyles,
    padding: '0 2px',
  }),
  clearIndicator: (baseStyles: Object) => ({
    ...baseStyles,
    padding: '0 2px',
  }),
  control: (baseStyles: Object) => ({
    ...baseStyles,
    borderRadius: 4,
    height: 'max-content',
    minHeight: R.or(height, 36),
    borderColor: G.renderBorderColor({ hasError }),
  }),
  valueContainer: (baseStyles: Object, props: Object) => {
    if (R.and(useMaxHeight, G.isNotNilAndNotEmpty(R.path(['selectProps', 'value'], props)))) {
      return {
        ...baseStyles,
        padding: '2px',
        overflow: 'auto',
        maxHeight: valueContainerMaxHeight,
      };
    }

    return baseStyles;
  },
});

const ReactSelect = (props: Object) => {
  const {
    value,
    width,
    height,
    isMulti,
    options,
    hasError,
    innerRef,
    isGrouped,
    useMaxHeight,
    additionalStyles,
    isClearable = true,
    useMenuPortalTarget,
    maxMenuHeight = 190,
    valueContainerMaxHeight,
    shouldNotGetValueFromOptions,
    noOptionsMessage = () => null,
  } = props;

  const styles = useMemo(() => ({
    ...makeDefaultStyles({ width, height, hasError, useMaxHeight, valueContainerMaxHeight }),
    ...additionalStyles,
  }), [hasError, additionalStyles]);

  const selectedValue = useMemo(() => {
    if (R.or(R.isNil(value), shouldNotGetValueFromOptions)) return value;

    let fullOptions = options;

    if (isGrouped) {
      fullOptions = R.compose(
        R.flatten,
        R.map(R.propOr([], 'options')),
      )(options);
    }

    if (isMulti) {
      return R.sort(
        (a: Object, b: Object) => R.subtract(
          R.indexOf(R.prop('value', a), value),
          R.indexOf(R.prop('value', b), value),
        ),
        R.filter((item: Object) => R.includes(R.prop('value', item), value), R.or(fullOptions, [])),
      );
    }

    return R.find(R.propEq(value, GC.FIELD_VALUE), R.or(fullOptions, []));
  }, [value, options, isMulti, isGrouped]);

  const menuProps = G.ifElse(
    useMenuPortalTarget,
    {
      menuPlacement: 'auto',
      menuPortalTarget: document.body,
      menuShouldScrollIntoView: false,
    },
  );

  const reactSelectProps = {
    ...menuProps,
    ...R.omit(
      [
        'value',
        'height',
        'hasError',
        'isGrouped',
        'useMaxHeight',
        'additionalStyles',
        'useMenuPortalTarget',
        'valueContainerMaxHeight',
        'shouldNotGetValueFromOptions',
      ],
      props,
    ),
    styles,
    isClearable,
    maxMenuHeight,
    noOptionsMessage,
    value: selectedValue,
  };

  return <Select {...reactSelectProps} ref={innerRef} />;
};

const DraggableMultiValue = (props: Object) => {
  const { index, data: { value } } = props;

  return (
    <Draggable index={index} draggableId={value}>
      {(provided: Object) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style, maxWidth: '100%' }}
        >
          <components.MultiValue {...props} />
        </div>
      )}
    </Draggable>
  );
};

const ReactSortableMultiselect = (props: Object) => {
  const {
    value,
    options,
    onChange,
    hasError,
    isClearable,
    maxMenuHeight,
    additionalStyles,
    useMenuPortalTarget,
    noOptionsMessage = () => null,
  } = props;

  const styles = useMemo(
    () => ({
      ...makeDefaultStyles(),
      multiValueRemove: (baseStyles: Object) => ({
        ...baseStyles,
        cursor: 'default',
      }),
      control: (baseStyles: Object, { getValue }: Object) => {
        const value = getValue();

        const common = {
          ...baseStyles,
          minHeight: 36,
          borderRadius: 4,
          height: 'max-content',
          borderColor: renderBorderColor({ hasError }),
        };

        const additional = {
          '& > div:first-of-type': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
        };

        return G.ifElse(G.isNilOrEmpty(value), common, { ...common, ...additional });
      },
      ...additionalStyles,
    }),
    [hasError, additionalStyles],
  );

  const selectedValue = useMemo(() => {
    if (R.isNil(value)) return value;

    return R.sort(
      (a: Object, b: Object) => R.subtract(
        R.indexOf(R.prop('value', a), value),
        R.indexOf(R.prop('value', b), value),
      ),
      R.filter((item: Object) => R.includes(R.prop('value', item), value), R.or(options, [])),
    );
  }, [value, options]);

  const onDragEnd = useCallback(
    ({ source, destination }: Object = {}) => {
      if (R.isNil(destination)) return;

      onChange(G.reorderList(selectedValue, source.index, destination.index));
    },
    [onChange, selectedValue],
  );

  const customComponents = useMemo(() => ({
    MultiValue: (multiValueProps: Object) => (
      <DraggableMultiValue {...multiValueProps} index={multiValueProps.index} />
    ),
  }), []);

  const menuProps = G.ifElse(
    useMenuPortalTarget,
    {
      menuPlacement: 'auto',
      menuPortalTarget: document.body,
      menuShouldScrollIntoView: false,
    },
  );

  const reactSelectProps = {
    ...menuProps,
    ...R.omit(
      [
        'value',
        'height',
        'hasError',
        'useMaxHeight',
        'additionalStyles',
        'useMenuPortalTarget',
        'valueContainerMaxHeight',
        'shouldNotGetValueFromOptions',
      ],
      props,
    ),
    styles,
    isClearable,
    maxMenuHeight,
    noOptionsMessage,
    value: selectedValue,
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable direction='vertical' droppableId='sortable-items'>
        {(provided: Object) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <Select
              {...reactSelectProps}
              isMulti={true}
              styles={styles}
              options={options}
              onChange={onChange}
              closeMenuOnSelect={false}
              components={customComponents}
              noOptionsMessage={noOptionsMessage}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const ReactAsyncSelect = (props: Object) => {
  const { id, width, placeholder, getLoadOptions, handleAsyncSearchSelectChange } = props;

  const styles = useMemo(() => ({
    ...makeDefaultStyles({ width }),
  }), []);

  return (
    <AsyncSelect
      id={id}
      styles={styles}
      cacheOptions={true}
      maxMenuHeight={190}
      placeholder={placeholder}
      onChange={handleAsyncSearchSelectChange}
      loadOptions={G.setDebounce(getLoadOptions, 1000)}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export {
  ReactSelect,
  ReactAsyncSelect,
  ReactSortableMultiselect,
};
