import styled from 'styled-components';
import {
  space,
  width,
  fontSize,
} from 'styled-system';
// helpers/constants
import * as G from '../helpers';
//////////////////////////////////////////////////

// TODO: check if it is possible to have one input everywhere
export const ChargeInput = styled.input`
  ${space}
  ${width}
  ${fontSize}

  cursor: text;
  height: 30px;
  outline: none;
  line-height: 30px;
  border-radius: 4px;
  border: 1px solid ${(props: Object) => G.renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};
  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
  }
`;

ChargeInput.defaultProps = {
  pr: 25,
  pl: 15,
};

export const SelectComponent = styled.select`
  ${space}
  ${width}
  ${fontSize}

  z-index: 0;
  height: 30px;
  outline: none;
  appearance: none;
  line-height: 30px;
  position: relative;
  border-radius: 4px;

  border: 1px solid ${(props: Object) => G.renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;

SelectComponent.defaultProps = {
  pl: 15,
  pr: 25,
};

export const ChargeInputSelect = styled.select`
  ${space}
  ${width}

  height: 30px;
  outline: none;
  appearance: none;
  line-height: 30px;
  position: relative;
  border-radius: 4px;
  padding-right: 25px;

  border: 1px solid ${(props: Object) => G.renderBorderColor(props)};
  background-color: ${({ disabled }: Object) => (
    G.getTheme(G.ifElse(disabled, 'forms.inputs.bgDisabled', 'forms.inputs.bgColor'))
  )};

  &:focus {
    box-shadow: 0 0 5px 0 rgba(206, 40, 40, 0.5);
  }
`;
