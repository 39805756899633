import ScriptTag from 'react-script-tag';
import { useDispatch } from 'react-redux';
import React, { lazy, Fragment, useEffect, Suspense } from 'react';
import {
  Outlet,
  useNavigate,
  useLocation,
  RouterProvider,
  createBrowserRouter,
} from 'react-router';
// helpers/constants
import * as GC from './constants';
import History from './helpers/route';
// ui
import { Box, ListWrapper } from './ui';
// features
import Login from './features/auth/login';
import OAuthPopup from './features/oauth-popup';
import NALLogin from './features/auth/nal-login';
import MainLayout from './components/main-layout';
import BoshLosRedirect from './features/auth/bosh-los-redirect';
import BoshLosCallback from './features/auth/bosh-los-callback';
import OrderListComponent from './features/dispatch-report/order';
//////////////////////////////////////////////////

// general
const LoadBoard = lazy(() => import('./features/load-board'));
const DashboardComponent = lazy(() => import('./features/dashboard'));
const DashboardsComponent = lazy(() => import('./features/dashboards'));
const CustomerPortal = lazy(() => import('./features/customer-portal'));
const DriversCardComponent = lazy(() => import('./features/drivers-card'));
const AvailableDriverList = lazy(() => import('./features/available-driver'));
const DispatchPlannerEvents = lazy(() => import('./features/dispatch-planner-events'));
// dispatch
const RouteListLoad = lazy(() => import('./features/dispatch-board-new/load'));
const RouteListOrder = lazy(() => import('./features/dispatch-board-new/order'));
const LoadDetailsNewComponent = lazy(() => import('./features/dispatch-details-new/load'));
const OrderDetailsNewComponent = lazy(() => import('./features/dispatch-details-new/order'));
// invoice
const DriverPayrollsComponent = lazy(() => import('./features/payroll'));
const CustomerMasterInvoicesComponent = lazy(() => import('./features/master-invoice'));
// templates
const TemplateInspection = lazy(() => import('./features/template/inspection'));
// views
const ChartsListComponent = lazy(() => import('./features/charts'));
const LoadListComponent = lazy(() => import('./features/dispatch-report/load'));
const RouteListComponent = lazy(() => import('./features/dispatch-report/route'));
const ChartFormComponent = lazy(() => import('./features/charts/components/chart-form'));
// fleet
const FleetList = lazy(() => import('./features/fleet-list'));
const FuelCardListComponent = lazy(() => import('./features/fuel-cards'));
const EditTruck = lazy(() => import('./features/fleet/truck/components/edit-form'));
const EditDriver = lazy(() => import('./features/fleet/driver/components/edit-form'));
const TrailerEdit = lazy(() => import('./features/fleet/trailer/components/edit-form'));
const EditFleetVendor = lazy(() => import('./features/fleet/vendor/components/edit-form'));

const startMouseFlow = () => {
  window._mfq = window._mfq || [];

  return '//cdn.mouseflow.com/projects/90f2fef0-3549-408a-8856-8f627e775da9.js';
};

const Fallback = () => <Box height='100vh' id='something' bg='bgGrey' />;

const Root = ({ withoutLayout }: Object) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  History.location = location;
  History.navigate = navigate;

  useEffect(() => {
    dispatch({ payload: location, type: GC.LOCATION_CHANGE_ACTION });
  }, [location]);

  if (withoutLayout) return null;

  return (
    <Fragment>
      <MainLayout />
      <ScriptTag type='text/javascript' src={startMouseFlow()} />
    </Fragment>
  );
};

const RootElement = () => {
  const location = useLocation();

  return (
    <Fragment>
      <Root />
      <Suspense key={location.key} fallback={<Fallback />}>
        <Outlet />
      </Suspense>
    </Fragment>
  );
};

const routes2 = (dispatch: Function) => [
  {
    path: '*',
    element: <Root />,
  },
  {
    hydrateFallbackElement: <Fallback />,
    element: (
      <Fragment>
        <Root withoutLayout={true} />
        <Outlet />
      </Fragment>
    ),
    children: [
      {
        Component: Login,
        path: GC.ROUTE_PATH_LOGIN,
      },
      {
        Component: NALLogin,
        path: GC.ROUTE_PATH_NAL_LOGIN,
        loader: () => {
          dispatch({ payload: {}, type: GC.VISIT_NAL_LOGIN_PAGE });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_NEW_PASSWORD,
        lazy: () => import('./features/auth/new-password'),
      },
      {
        path: GC.ROUTE_PATH_RESET_PASSWORD,
        lazy: () => import('./features/auth/reset-password'),
      },
      {
        path: GC.ROUTE_PATH_TERMS_AND_CONDITIONS,
        lazy: () => import('./features/auth/legal-info/terms-and-conditions'),
      },
      {
        path: GC.ROUTE_PATH_PRIVACY_POLICY,
        lazy: () => import('./features/auth/legal-info/privacy-policy'),
      },
      {
        path: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE,
        lazy: () => import('./features/auth/new-password-mobile'),
      },
      {
        path: GC.ROUTE_PATH_NEW_PASSWORD_MOBILE_SUCCESS_PAGE,
        lazy: () => import('./features/auth/new-password-mobile-success-page'),
      },
      {
        path: GC.ROUTE_PATH_CARRIERS_RESPONSE,
        lazy: () => import('./features/carrier-portal/response-page'),
      },
      {
        path: GC.ROUTE_PATH_CARRIERS_ACCEPT,
        lazy: () => import('./features/carrier-portal/carrier-accept'),
        // example
        // loader: ({ params }: Object) => {
        //   dispatch({ payload: {}, type: GC.VISIT_PORTAL_ACCEPT_PAGE });

        //   return null;
        // },
      },
      {
        path: GC.ROUTE_PATH_CARRIERS_DECLINE,
        lazy: () => import('./features/carrier-portal/carrier-decline'),
      },
      {
        path: GC.ROUTE_PATH_ORDER_QUOTE_ACCEPT,
        lazy: () => import('./features/carrier-portal/carrier-accept'),
      },
      {
        path: GC.ROUTE_PATH_ORDER_QUOTE_DECLINE,
        lazy: () => import('./features/carrier-portal/carrier-decline'),
      },
      {
        path: GC.ROUTE_PATH_CARRIER_QUOTE_ACCEPT,
        lazy: () => import('./features/carrier-portal/carrier-accept'),
      },
      {
        path: GC.ROUTE_PATH_CARRIER_QUOTE_DECLINE,
        lazy: () => import('./features/carrier-portal/carrier-decline'),
      },
      {
        path: GC.ROUTE_PATH_CARRIERS_ADD_MESSAGE_PAGE,
        lazy: () => import('./features/carrier-portal'),
      },
      {
        Component: BoshLosRedirect,
        path: GC.ROUTE_PATH_BOSH_LOS_REDIRECT,
        loader: () => {
          dispatch({ type: GC.VISIT_BOSH_REDIRECT_PAGE });
        },
      },
      {
        element: <BoshLosCallback />,
        path: GC.ROUTE_PATH_BOSH_LOS_CALLBACK,
        loader: () => {
          dispatch({ type: GC.VISIT_BOSH_CALLBACK_PAGE });

          return null;
        },
      },
    ],
  },
  {
    element: <RootElement />,
    hydrateFallbackElement: <Fallback />,
    children: [
      {
        path: GC.ROUTE_PATH_DO_NEW,
        lazy: () => import('./features/new-do'),
        loader: () => dispatch({
          type: GC.VISIT_NEW_DO_PAGE,
          payload: GC.ROUTE_PATH_DO_NEW,
        }),
      },
      {
        lazy: () => import('./features/new-do'),
        path: GC.ROUTE_PATH_DO_NEW_FROM_BY_SOURCE_TYPE,
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_NEW_DO_PAGE_BY_SOURCE_TYPE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_DO_NEW_FROM_BY_SOURCE_TYPE,
          },
        }),
      },
      {
        lazy: () => import('./features/lite-new-do'),
        path: GC.ROUTE_PATH_LITE_DO_NEW_BY_SOURCE_TYPE,
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_LITE_NEW_DO_PAGE_BY_SOURCE_TYPE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_LITE_DO_NEW_BY_SOURCE_TYPE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_DO_QUOTE,
        lazy: () => import('./features/new-do-quote'),
        loader: () => dispatch({
          payload: GC.ROUTE_PATH_DO_QUOTE,
          type: GC.VISIT_NEW_DO_QUOTE_PAGE,
        }),
      },
      {
        path: GC.ROUTE_PATH_LITE_DO_NEW,
        lazy: () => import('./features/lite-new-do'),
        loader: () => dispatch({
          type: GC.VISIT_LITE_NEW_DO_PAGE,
          payload: GC.ROUTE_PATH_LITE_DO_NEW,
        }),
      },
      {
        path: GC.ROUTE_PATH_DASHBOARDS,
        Component: DashboardsComponent,
        loader: () => dispatch({
          type: GC.VISIT_DASHBOARDS_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_DASHBOARDS,
          },
        }),
      },
      {
        Component: DashboardComponent,
        path: GC.ROUTE_PATH_ANALYTICS,
        loader: () => dispatch({
          type: GC.VISIT_ANALYTICS_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_ANALYTICS,
          },
        }),
      },
      {
        Component: CustomerPortal,
        path: GC.ROUTE_PATH_CUSTOMER_PORTAL,
        loader: () => dispatch({
          type: GC.VISIT_CUSTOMER_PORTAL_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_CUSTOMER_PORTAL,
          },
        }),
      },
      {
        Component: DriversCardComponent,
        path: GC.ROUTE_PATH_DRIVERS_CARD,
        loader: () => dispatch({
          type: GC.VISIT_DRIVERS_CARD_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_DRIVERS_CARD,
          },
        }),
      },
      {
        Component: AvailableDriverList,
        path: GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST,
        loader: () => dispatch({
          type: GC.VISIT_ROUTE_AVAILABLE_DRIVER_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_AVAILABLE_DRIVER_LIST,
          },
        }),
      },
      {
        Component: DispatchPlannerEvents,
        path: GC.ROUTE_PATH_LOAD_PLANNER,
        loader: () => dispatch({
          type: GC.VISIT_LOAD_PLANNER_PAGE,
          payload: {
            route: GC.ROUTE_PATH_LOAD_PLANNER,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_IMPORT,
        lazy: () => import('./features/new-import'),
        loader: () => dispatch({
          type: GC.VISIT_IMPORT_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_IMPORT,
          },
        }),
      },
      {
        Component: LoadBoard,
        path: GC.ROUTE_PATH_LOAD_BOARD,
        loader: () => dispatch({
          type: GC.VISIT_LOAD_BOARD_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_LOAD_BOARD,
          },
        }),
      },
      // dispatch
      {
        Component: RouteListLoad,
        path: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
        loader: () => dispatch({
          type: GC.VISIT_DISPATCH_BOARD_TEL_PAGE,
          payload: {
            route: GC.ROUTE_PATH_DISPATCH_BOARD_TEL,
          },
        }),
      },
      {
        Component: RouteListOrder,
        path: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
        loader: () => dispatch({
          type: GC.VISIT_DISPATCH_BOARD_CLO_PAGE,
          payload: {
            route: GC.ROUTE_PATH_DISPATCH_BOARD_CLO,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_ROUTE_LOAD,
        Component: LoadDetailsNewComponent,
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_DISPATCH_DETAILS_LOAD_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_ROUTE_LOAD,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_ROUTE_ORDER,
        Component: OrderDetailsNewComponent,
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_DISPATCH_DETAILS_ORDER_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_ROUTE_ORDER,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_EDI_LIST,
        lazy: () => import('./features/edi-integration'),
        loader: () => dispatch({
          type: GC.VISIT_EDI_LIST_PAGE,
          payload: {
            reportType: GC.EDI_REPORT,
            pathname: GC.ROUTE_PATH_EDI_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CARRIER_EDI_LIST,
        lazy: () => import('./features/carrier-edi'),
        loader: () => dispatch({
          type: GC.VISIT_CARRIER_EDI_LIST_PAGE,
          payload: {
            reportType: GC.INVOICE_EDI_REPORT,
            pathname: GC.ROUTE_PATH_CARRIER_EDI_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
        lazy: () => import('./features/public-clo'),
        loader: () => dispatch({
          type: GC.VISIT_PUBLIC_CLO_LIST_PAGE,
          payload: {
            reportType: GC.ORDER_REQUEST_REPORT,
            pathname: GC.ROUTE_PATH_PUBLIC_CLO_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_QUOTE,
        lazy: () => import('./features/quotes'),
        loader: () => dispatch({ type: GC.VISIT_QUOTES_LIST_PAGE }),
      },
      {
        path: GC.ROUTE_PATH_ORDER_QUOTES,
        lazy: () => import('./features/quotes'),
        loader: () => dispatch({ type: GC.VISIT_ORDER_QUOTES_LIST_PAGE }),
      },
      // invoice
      {
        element: (
          <ListWrapper>
            <Outlet />
          </ListWrapper>
        ),
        children: [
          {
            path: GC.ROUTE_PATH_DRIVER_INVOICES_LIST,
            lazy: () => import('./features/invoice/driver'),
            loader: () => dispatch({
              type: GC.VISIT_INVOICE_DRIVER_PAYROLL_LIST_PAGE,
              payload: {
                pathname: GC.ROUTE_PATH_DRIVER_INVOICES_LIST,
                reportType: GC.DRIVER_PAYROLL_INVOICE_REPORT,
              },
            }),
          },
          {
            path: GC.ROUTE_PATH_VENDOR_INVOICES_LIST,
            lazy: () => import('./features/invoice/vendor'),
            loader: () => dispatch({
              type: GC.VISIT_INVOICE_VENDOR_LIST_PAGE,
              payload: {
                reportType: GC.VENDOR_INVOICE_REPORT,
                pathname: GC.ROUTE_PATH_VENDOR_INVOICES_LIST,
              },
            }),
          },
          {
            path: GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST,
            lazy: () => import('./features/invoice/customer'),
            loader: () => dispatch({
              type: GC.VISIT_INVOICE_CUSTOMER_LIST_PAGE,
              payload: {
                reportType: GC.CUSTOMER_INVOICE_REPORT,
                pathname: GC.ROUTE_PATH_CUSTOMER_INVOICES_LIST,
              },
            }),
          },
          {
            path: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
            lazy: () => import('./features/invoice/carrier'),
            loader: () => dispatch({
              type: GC.VISIT_INVOICE_CARRIER_LIST_PAGE,
              payload: {
                reportType: GC.CARRIER_INVOICE_REPORT,
                pathname: GC.ROUTE_PATH_CARRIER_INVOICES_LIST,
              },
            }),
          },
          {
            path: GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST,
            lazy: () => import('./features/invoice/service-vendor'),
            loader: () => dispatch({
              type: GC.VISIT_INVOICE_SERVICE_VENDOR_LIST_PAGE,
              payload: {
                reportType: GC.SERVICE_VENDOR_INVOICE_REPORT,
                pathname: GC.ROUTE_PATH_SERVICE_VENDOR_INVOICE_LIST,
              },
            }),
          },
        ],
      },
      {
        Component: CustomerMasterInvoicesComponent,
        path: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
        loader: () => dispatch({
          type: GC.VISIT_CUSTOMER_MASTER_INVOICES_PAGE,
          payload: {
            reportType: GC.CUSTOMER_MASTER_INVOICE_REPORT,
            pathname: GC.ROUTE_PATH_CUSTOMER_MASTER_INVOICE_LIST,
          },
        }),
      },
      {
        Component: DriverPayrollsComponent,
        path: GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
        loader: () => dispatch({
          type: GC.VISIT_DRIVER_PAYROLL_PAGE,
          payload: {
            reportType: GC.PAYROLL_REPORT,
            pathname: GC.ROUTE_PATH_DRIVER_PAYROLL_LIST,
          },
        }),
      },
      // contracts
      {
        path: GC.ROUTE_PATH_CARRIER_CONTRACTS,
        lazy: () => import('./features/contracts/carrier'),
        loader: () => dispatch({
          type: GC.VISIT_CARRIER_CONTRACTS_PAGE,
          payload: {
            reportType: GC.CARRIER_CONTRACT_REPORT,
            pathname: GC.ROUTE_PATH_CARRIER_CONTRACTS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CUSTOMER_CONTRACTS,
        lazy: () => import('./features/contracts/customer'),
        loader: () => dispatch({
          type: GC.VISIT_CUSTOMER_CONTRACTS_PAGE,
          payload: {
            reportType: GC.CUSTOMER_CONTRACT_REPORT,
            pathname: GC.ROUTE_PATH_CUSTOMER_CONTRACTS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS,
        lazy: () => import('./features/carrier-profile/contracts/carrier'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_CARRIER_CONTRACT_DETAILS_PAGE,
          payload: {
            ...params,
            pathname: GC.ROUTE_PATH_CARRIER_CONTRACT_DETAILS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CUSTOMER_CONTRACT_DETAILS,
        lazy: () => import('./features/carrier-profile/contracts/customer'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_CUSTOMER_CONTRACT_DETAILS_PAGE,
          payload: {
            ...params,
            pathname: GC.ROUTE_PATH_CUSTOMER_CONTRACT_DETAILS,
          },
        }),
      },
      // templates
      {
        path: GC.ROUTE_PATH_ITEMS_LIST,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_ITEMS_LIST_PAGE,
          payload: {
            listType: 'listUp',
            reportType: GC.ITEM_REPORT,
            pathname: GC.ROUTE_PATH_ITEMS_LIST,
            checkVisitPage: GC.CHECK_VISIT_ITEMS_LIST_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_LOCATIONS_LIST,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_LOCATIONS_LIST_PAGE,
          payload: {
            listType: 'listUp',
            pathname: GC.ROUTE_PATH_LOCATIONS_LIST,
            reportType: GC.LOCATION_TEMPLATE_REPORT,
            checkVisitPage: GC.CHECK_VISIT_LOCATIONS_LIST_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CONTACT_BOOK,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_CONTACT_BOOK_PAGE,
          payload: {
            listType: 'listUp',
            reportType: GC.CONTACT_BOOK_REPORT,
            pathname: GC.ROUTE_PATH_CONTACT_BOOK,
            checkVisitPage: GC.CHECK_VISIT_CONTACT_BOOK_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_SHARED_ACCESSORIALS,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_SHARED_ACCESORIALS_PAGE,
          payload: {
            listType: 'list',
            reportType: GC.SHARED_ACCESSORIAL_REPORT,
            pathname: GC.ROUTE_PATH_SHARED_ACCESSORIALS,
            checkVisitPage: GC.CHECK_VISIT_CONTACT_BOOK_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TEMPLATES_DO_LIST,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_DO_TEMPLATE_LIST_PAGE,
          payload: {
            listType: 'list',
            reportType: GC.CLO_TEMPLATE_REPORT,
            pathname: GC.ROUTE_PATH_TEMPLATES_DO_LIST,
            checkVisitPage: GC.CHECK_VISIT_DO_TEMPLATE_LIST_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TEMPLATE_DO_DETAILS,
        lazy: () => import('./features/new-do'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_TEMPLATE_DETAILS_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_TEMPLATE_DO_DETAILS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS,
        lazy: () => import('./features/lite-new-do'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_LITE_TEMPLATE_DETAILS_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_LITE_TEMPLATE_DO_DETAILS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_CONTAINER_TEMPLATE_LIST_PAGE,
          payload: {
            listType: 'listUp',
            reportType: GC.CONTAINER_TEMPLATE_REPORT,
            pathname: GC.ROUTE_PATH_TEMPLATE_CONTAINER_LIST,
            checkVisitPage: GC.CHECK_VISIT_CONTAINER_TEMPLATE_LIST_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_ROUTE_TEMPLATE_LIST_PAGE,
          payload: {
            listType: 'list',
            reportType: GC.ROUTE_TEMPLATE_REPORT,
            pathname: GC.ROUTE_PATH_TEMPLATES_ROUTE_LIST,
            checkVisitPage: GC.CHECK_VISIT_ROUTE_TEMPLATE_LIST_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST,
        lazy: () => import('./features/template/report'),
        loader: () => dispatch({
          type: GC.VISIT_COMPENSATION_TEMPLATE_LIST_PAGE,
          payload: {
            listType: 'listUp',
            reportType: GC.COMPENSATION_TEMPLATE_REPORT,
            pathname: GC.ROUTE_PATH_TEMPLATES_COMPENSATION_LIST,
            checkVisitPage: GC.CHECK_VISIT_COMPENSATION_TEMPLATE_LIST_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CONTAINER_TYPE_LIST,
        lazy: () => import('./features/template/container-type'),
        loader: () => dispatch({
          type: GC.VISIT_CONTAINER_TYPE_LIST_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_CONTAINER_TYPE_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_COMMISSION_ASSIGNMENT,
        lazy: () => import('./features/commission-assignment'),
        loader: () => dispatch({
          type: GC.VISIT_COMMISSION_ASSIGNMENT_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_COMMISSION_ASSIGNMENT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_GEO_FENCING_ZONE,
        lazy: () => import('./features/geo-fencing-zone/rating'),
        loader: () => dispatch({
          type: GC.VISIT_RATING_GEO_FENCING_ZONE_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_GEO_FENCING_ZONE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT,
        lazy: () => import('./features/geo-fencing-zone/report'),
        loader: () => dispatch({
          type: GC.VISIT_REPORT_GEO_FENCING_ZONE_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_GEO_FENCING_ZONE_REPORT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST,
        lazy: () => import('./features/load-planner-template'),
        loader: () => dispatch({
          type: GC.VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE,
          payload: {
            listType: 'list',
            pathname: GC.ROUTE_PATH_LOAD_PLANNER_TEMPLATE_LIST,
            checkVisitPage: GC.CHECK_VISIT_LOAD_PLANNER_TEMPLATE_LIST_PAGE,
          },
        }),
      },
      {
        Component: TemplateInspection,
        path: GC.ROUTE_PATH_TEMPLATES_INSPECTION,
        loader: () => dispatch({
          type: GC.VISIT_TEMPLATE_INSPECTION_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_TEMPLATES_INSPECTION,
          },
        }),
      },
      // views
      {
        path: GC.ROUTE_PATH_USERS_LIST,
        lazy: () => import('./features/user'),
        loader: () => {
          dispatch({ type: GC.VISIT_USERS_LIST_PAGE });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_USERS_SETTINGS,
        lazy: () => import('./features/profile'),
        loader: ({ params }: Object) => {
          dispatch({
            type: GC.VISIT_USER_EDIT_PAGE,
            payload: { ...params, route: GC.ROUTE_PATH_USERS_SETTINGS },
          });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_PROFILE,
        lazy: () => import('./features/profile'),
        loader: () => {
          dispatch({ type: GC.VISIT_CURRENT_USER_PROFILE });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_ROLES_LIST,
        lazy: () => import('./features/role'),
        loader: () => {
          dispatch({ type: GC.VISIT_ROLES_LIST_PAGE, payload: GC.ROUTE_PATH_ROLES_LIST });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_ROLES_EDIT,
        lazy: () => import('./features/role/components/edit-role'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_ROLE_EDIT_PAGE,
          payload: { ...params, route: GC.ROUTE_PATH_ROLES_EDIT },
        }),
      },
      {
        path: GC.ROUTE_PATH_ROLES_NEW,
        lazy: () => import('./features/role/components/create-role'),
        loader: () => dispatch({
          type: GC.VISIT_ROLE_CREATE_PAGE,
          payload: GC.ROUTE_PATH_ROLES_NEW,
        }),
      },
      {
        path: GC.ROUTE_PATH_CARRIERS_LIST,
        lazy: () => import('./features/carrier'),
        loader: () => {
          dispatch({
            type: GC.VISIT_CARRIER_LIST_PAGE,
            payload: {
              reportType: GC.CARRIER_REPORT,
              pathname: GC.ROUTE_PATH_CARRIERS_LIST,
            },
          });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_CARRIER_EDIT,
        lazy: () => import('./features/carrier/components/edit-form'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_CARRIER_EDIT_PAGE,
          payload: {
            ...params,
            pathname: GC.ROUTE_PATH_CARRIER_EDIT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CARRIER_PROFILE,
        lazy: () => import('./features/carrier-profile'),
        loader: ({ params }: Object) => {
          dispatch({ type: GC.VISIT_CARRIER_PROFILE_PAGE, payload: params });

          return null;
        },
      },
      {
        path: GC.ROUTE_PATH_SERVICE_VENDOR_LIST,
        lazy: () => import('./features/service-vendor'),
        loader: () => dispatch({
          type: GC.VISIT_SERVICE_VENDOR_LIST_PAGE,
          payload: {
            reportType: GC.SERVICE_VENDOR_REPORT,
            pathname: GC.ROUTE_PATH_SERVICE_VENDOR_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_SERVICE_VENDOR_EDIT,
        lazy: () => import('./features/service-vendor/components/edit-form'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_SERVICE_VENDOR_EDIT_PAGE,
          payload: {
            ...params,
            pathname: GC.ROUTE_PATH_SERVICE_VENDOR_EDIT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_BRANCH_LIST,
        lazy: () => import('./features/branch'),
        loader: () => dispatch({
          type: GC.VISIT_BRANCH_LIST_PAGE,
          payload: {
            reportType: GC.BRANCH_REPORT,
            pathname: GC.ROUTE_PATH_BRANCH_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_BRANCH_EDIT,
        lazy: () => import('./features/branch/components/edit-branch'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_BRANCH_EDIT_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_BRANCH_EDIT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_SEQUENCES_LIST,
        lazy: () => import('./features/sequence'),
        loader: () => dispatch({ type: GC.VISIT_SEQUENCES_LIST_PAGE, payload: GC.ROUTE_PATH_SEQUENCES_LIST }),
      },
      {
        path: GC.ROUTE_PATH_REFERENCES_LIST,
        lazy: () => import('./features/reference'),
        loader: () => dispatch({
          payload: GC.ROUTE_PATH_REFERENCES_LIST,
          type: GC.VISIT_REFERENCE_TYPES_LIST_PAGE,
        }),
      },
      {
        path: GC.ROUTE_PATH_REPORTS_LIST,
        lazy: () => import('./features/report-format'),
        loader: () => dispatch({
          type: GC.VISIT_REPORTS_LIST_PAGE,
          payload: {
            reportType: GC.REPORT_REPORT,
            pathname: GC.ROUTE_PATH_REPORTS_LIST,
          },
        }),
      },
      {
        Component: OrderListComponent,
        path: GC.ROUTE_PATH_CLO_REPORT_LIST,
        loader: () => dispatch({
          type: GC.VISIT_CLO_LIST_PAGE,
          payload: {
            reportType: GC.CLO_REPORT,
            pathname: GC.ROUTE_PATH_CLO_REPORT_LIST,
          },
        }),
      },
      {
        Component: LoadListComponent,
        path: GC.ROUTE_PATH_TEL_REPORT_LIST,
        loader: () => dispatch({
          type: GC.VISIT_TEL_LIST_PAGE,
          payload: {
            reportType: GC.TEL_REPORT,
            pathname: GC.ROUTE_PATH_TEL_REPORT_LIST,
          },
        }),
      },
      {
        Component: RouteListComponent,
        path: GC.ROUTE_PATH_ROUTE_REPORT_LIST,
        loader: () => dispatch({
          type: GC.VISIT_ROUTE_REPORT_LIST_PAGE,
          payload: {
            reportType: GC.ROUTE_REPORT,
            pathname: GC.ROUTE_PATH_ROUTE_REPORT_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_ROUTE_BUILDER,
        lazy: () => import('./features/dispatch-planner'),
        loader: () => dispatch({
          type: GC.VISIT_ROUTE_BUILDER_PAGE,
          payload: {
            route: GC.ROUTE_PATH_ROUTE_BUILDER,
          },
        }),
      },
      {
        Component: ChartsListComponent,
        path: GC.ROUTE_PATH_CHARTS_LIST,
        loader: () => dispatch({
          type: GC.VISIT_CHART_LIST_PAGE,
          payload: {
            reportType: GC.CHART_REPORT,
            pathname: GC.ROUTE_PATH_CHARTS_LIST,
          },
        }),
      },
      {
        Component: ChartFormComponent,
        path: GC.ROUTE_PATH_CHARTS_NEW,
        loader: () => dispatch({
          type: GC.VISIT_CHART_CREATE_PAGE,
          payload: GC.ROUTE_PATH_CHARTS_NEW,
        }),
      },
      {
        path: GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST,
        lazy: () => import('./features/normalize-currency-list'),
        loader: () => dispatch({
          type: GC.VISIT_NORMALIZE_CURRENCY_LIST_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_NORMALIZE_CURRENCY_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_AVERAGE_FUEL_PRICE,
        lazy: () => import('./features/average-fuel-price'),
        loader: () => dispatch({
          type: GC.VISIT_AVERAGE_FUEL_PRICE_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_AVERAGE_FUEL_PRICE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_DISPATCH_GROUP,
        lazy: () => import('./features/dispatch-group'),
        loader: () => dispatch({
          type: GC.VISIT_DISPATCH_GROUP_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_DISPATCH_GROUP,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT,
        lazy: () => import('./features/charge-report'),
        loader: () => dispatch({
          type: GC.VISIT_CLO_RATE_CHARGE_REPORT_PAGE,
          payload: {
            reportType: GC.CLO_RATE_CHARGE_REPORT,
            pathname: GC.ROUTE_PATH_CLO_RATE_CHARGE_REPORT,
            checkVisitPage: GC.CHECK_VISIT_CLO_RATE_CHARGE_REPORT_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT,
        lazy: () => import('./features/charge-report'),
        loader: () => dispatch({
          type: GC.VISIT_TEL_RATE_CHARGE_REPORT_PAGE,
          payload: {
            reportType: GC.TEL_RATE_CHARGE_REPORT,
            pathname: GC.ROUTE_PATH_TEL_RATE_CHARGE_REPORT,
            checkVisitPage: GC.CHECK_VISIT_TEL_RATE_CHARGE_REPORT_PAGE,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
        lazy: () => import('./features/task-management/board'),
        loader: () => dispatch({
          type: GC.VISIT_TASK_MANAGEMENT_PAGE,
          payload: {
            type: 'board',
            pathname: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT,
        lazy: () => import('./features/task-management/report'),
        loader: () => dispatch({
          type: GC.VISIT_TASK_MANAGEMENT_PAGE,
          payload: {
            reportType: GC.TASK_MANAGEMENT_REPORT,
            pathname: GC.ROUTE_PATH_TASK_MANAGEMENT_REPORT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD_LIST,
        lazy: () => import('./features/task-management/board-list'),
        loader: () => dispatch({
          type: GC.VISIT_TASK_MANAGEMENT_PAGE,
          payload: {
            type: 'boardList',
            pathname: GC.ROUTE_PATH_TASK_MANAGEMENT_BOARD,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
        lazy: () => import('./features/claim-management/report'),
        loader: () => dispatch({
          type: GC.VISIT_CLAIM_MANAGEMENT_LIST_PAGE,
          payload: {
            reportType: GC.CLAIM_REPORT,
            pathname: GC.ROUTE_PATH_CLAIM_MANAGEMENT_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS,
        lazy: () => import('./features/claim-management/details'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_CLAIM_MANAGEMENT_CLAIM_DETAILS_PAGE,
          payload: {
            ...params,
            pathname: GC.ROUTE_PATH_CLAIM_MANAGEMENT_CLAIM_DETAILS,
          },
        }),
      },
      // fleet
      {
        element: (
          <ListWrapper p='0'>
            <Outlet />
          </ListWrapper>
        ),
        children: [
          {
            path: GC.ROUTE_PATH_FLEET_MAP,
            lazy: () => import('./features/fleet-map'),
            loader: () => dispatch({
              type: GC.VISIT_ANALYTIC_MAPS_PAGE,
              payload: {
                pathname: GC.ROUTE_PATH_FLEET_MAP,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_DRIVERS_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_DRIVER_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_DRIVER_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_DRIVERS_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_DRIVERS_LIST_PAGE,
              },
            }),
          },
          {
            Component: EditDriver,
            path: GC.ROUTE_PATH_FLEET_DRIVERS_EDIT,
            loader: ({ params }: Object) => dispatch({
              type: GC.VISIT_FLEET_DRIVER_EDIT_PAGE,
              payload: {
                ...params,
                route: GC.ROUTE_PATH_FLEET_DRIVERS_EDIT,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_TRAILERS_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_TRAILERS_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_TRAILER_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_TRAILERS_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_TRAILERS_LIST_PAGE,
              },
            }),
          },
          {
            Component: TrailerEdit,
            path: GC.ROUTE_PATH_FLEET_TRAILERS_EDIT,
            loader: ({ params }: Object) => dispatch({
              type: GC.VISIT_FLEET_TRAILER_EDIT_PAGE,
              payload: {
                ...params,
                route: GC.ROUTE_PATH_FLEET_TRAILERS_EDIT,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_TRUCKS_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_TRUCKS_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_TRUCK_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_TRUCKS_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_TRUCKS_LIST_PAGE,
              },
            }),
          },
          {
            Component: EditTruck,
            path: GC.ROUTE_PATH_FLEET_TRUCKS_EDIT,
            loader: ({ params }: Object) => dispatch({
              type: GC.VISIT_FLEET_TRUCK_EDIT_PAGE,
              payload: {
                ...params,
                route: GC.ROUTE_PATH_FLEET_TRUCKS_EDIT,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_VENDOR_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_VENDOR_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_VENDOR_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_VENDOR_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_VENDOR_LIST_PAGE,
              },
            }),
          },
          {
            Component: EditFleetVendor,
            path: GC.ROUTE_PATH_FLEET_VENDOR_EDIT,
            loader: ({ params }: Object) => dispatch({
              type: GC.VISIT_FLEET_VENDOR_EDIT_PAGE,
              payload: {
                ...params,
                route: GC.ROUTE_PATH_FLEET_VENDOR_EDIT,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_EQUIPMENT_SERVICE_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_EQUIPMENT_SERVICE_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_EQUIPMENT_SERVICE_LIST_PAGE,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_SHARED_COMPONENT_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_SHARED_COMPONENT_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_SHARED_COMPONENT_LIST_PAGE,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_DRIVER_PAYROLL_ASSESSORIAL_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_DRIVER_PAYROLL_ACCESSORIAL_LIST_PAGE,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_VENDOR_PAYROLL_ASSESSORIAL_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_VENDOR_PAYROLL_ACCESSORIAL_LIST_PAGE,
              },
            }),
          },
          {
            Component: FleetList,
            path: GC.ROUTE_PATH_FLEET_SERVICE_ISSUES_LIST,
            loader: () => dispatch({
              type: GC.VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE,
              payload: {
                reportType: GC.FLEET_SERVICE_ISSUE_REPORT,
                pathname: GC.ROUTE_PATH_FLEET_SERVICE_ISSUES_LIST,
                checkVisitPageType: GC.CHECK_VISIT_FLEET_SERVICE_ISSUES_LIST_PAGE,
              },
            }),
          },
          {
            path: GC.ROUTE_PATH_GEO_FENCING_LOCATION,
            lazy: () => import('./features/geo-fencing-location'),
            loader: () => dispatch({
              type: GC.VISIT_GEO_FENCING_LOCATION_PAGE,
              payload: {
                pathname: GC.ROUTE_PATH_GEO_FENCING_LOCATION,
              },
            }),
          },
        ],
      },
      {
        path: GC.ROUTE_PATH_FUEL_CARDS,
        Component: FuelCardListComponent,
        loader: () => dispatch({
          type: GC.VISIT_FUEL_CARDS_PAGE,
          payload: {
            reportType: GC.FUEL_CARD_REPORT,
            pathname: GC.ROUTE_PATH_FUEL_CARDS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_TOLL_CHARGES,
        lazy: () => import('./features/toll-charges'),
        loader: () => dispatch({
          type: GC.VISIT_TOLL_CHARGES_PAGE,
          payload: {
            reportType: GC.TOLL_CHARGE_REPORT,
            pathname: GC.ROUTE_PATH_TOLL_CHARGES,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_IFTA_REPORT,
        lazy: () => import('./features/ifta-report'),
        loader: () => dispatch({
          type: GC.VISIT_IFTA_REPORT_PAGE,
          payload: {
            reportType: GC.IFTA_REPORT,
            pathname: GC.ROUTE_PATH_IFTA_REPORT,
          },
        }),
      },
      // fleet-profile
      {
        path: GC.ROUTE_PATH_FLEET_PROFILE_DRIVER,
        lazy: () => import('./features/fleet-profile/driver'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_FLEET_PROFILE_DRIVER_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_FLEET_PROFILE_DRIVER,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_FLEET_PROFILE_VENDOR,
        lazy: () => import('./features/fleet-profile/vendor'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_FLEET_PROFILE_VENDOR_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_FLEET_PROFILE_VENDOR,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_FLEET_PROFILE_TRUCK,
        lazy: () => import('./features/fleet-profile/truck'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_FLEET_PROFILE_TRUCK_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_FLEET_PROFILE_TRUCK,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_FLEET_PROFILE_TRAILER,
        lazy: () => import('./features/fleet-profile/trailer'),
        loader: ({ params }: Object) => dispatch({
          type: GC.VISIT_FLEET_PROFILE_TRAILER_PAGE,
          payload: {
            ...params,
            route: GC.ROUTE_PATH_FLEET_PROFILE_TRAILER,
          },
        }),
      },
      // background processes
      {
        path: GC.ROUTE_PATH_CARRIER_RATING_REPORT,
        lazy: () => import('./features/carrier-rating-report'),
        loader: () => dispatch({
          type: GC.VISIT_CARRIER_RATING_REPORT_LIST_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_CARRIER_RATING_REPORT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_IMPORT_REPORT,
        lazy: () => import('./features/import-report'),
        loader: () => dispatch({
          type: GC.VISIT_IMPORT_REPORT_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_IMPORT_REPORT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_RATE_SHOP,
        lazy: () => import('./features/rate-shop'),
        loader: () => dispatch({
          type: GC.VISIT_RATE_SHOP_PAGE,
          payload: {
            pathname: GC.VISIT_RATE_SHOP_PAGE,
            reportType: GC.EXCEL_RATING_RESULT_REPORT,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT,
        lazy: () => import('./features/route-mass-create-report'),
        loader: () => dispatch({
          type: GC.VISIT_ROUTE_MASS_CREATE_REPORT_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_ROUTE_MASS_CREATE_REPORT,
          },
        }),
      },
      // configs
      {
        path: GC.ROUTE_PATH_CONFIG_PASSWORD,
        lazy: () => import('./features/configurations/config-pages/password'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_PASSWORD_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_PASSWORD,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_TEL,
        lazy: () => import('./features/configurations/config-pages/tel'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_TEL_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_TEL,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_CLO,
        lazy: () => import('./features/configurations/config-pages/clo'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_CLO_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_CLO,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_GENERAL,
        lazy: () => import('./features/configurations/config-pages/general'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_GENERAL_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_GENERAL,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_OPTIONAL,
        lazy: () => import('./features/configurations/config-pages/optional'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_OPTIONAL_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_OPTIONAL,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_TEMPLATES,
        lazy: () => import('./features/configurations/config-pages/templates'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_TEMPLATES_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_TEMPLATES,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_DRIVER,
        lazy: () => import('./features/configurations/config-pages/driver'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_DRIVER_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_DRIVER,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_FLEET_GENERAL,
        lazy: () => import('./features/configurations/config-pages/fleet-general'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_FLEET_GENERAL_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_FLEET_GENERAL,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_TRAILER,
        lazy: () => import('./features/configurations/config-pages/trailer'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_TRAILER_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_TRAILER,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_TRUCK,
        lazy: () => import('./features/configurations/config-pages/truck'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_TRUCK_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_TRUCK,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_INVOICES,
        lazy: () => import('./features/configurations/config-pages/invoice'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_INVOICES_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_INVOICES,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_RATING,
        lazy: () => import('./features/configurations/config-pages/rating'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_RATING_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_RATING,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_COMMUNICATION,
        lazy: () => import('./features/configurations/config-pages/communication'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_COMMUNICATION_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_COMMUNICATION,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_CARRIER,
        lazy: () => import('./features/configurations/config-pages/carrier'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_CARRIER_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_CARRIER,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR,
        lazy: () => import('./features/configurations/config-pages/service-vendor'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_SERVICE_VENDOR_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_SERVICE_VENDOR,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_INTEGRATION,
        lazy: () => import('./features/configurations/config-pages/integration'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_INTEGRATION_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_INTEGRATION,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_UI,
        lazy: () => import('./features/configurations/config-pages/ui'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_UI_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_UI,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_MOBILEAPP,
        lazy: () => import('./features/configurations/config-pages/mobileapp'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_MOBILEAPP_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_MOBILEAPP,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_AUDIT,
        lazy: () => import('./features/configurations/config-pages/audit'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_AUDIT_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_AUDIT,
        }),
      },
      {

        path: GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT,
        lazy: () => import('./features/configurations/config-pages/task-management'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_TASK_MANAGEMENT_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_TASK_MANAGEMENT,
        }),
      },
      {
        path: GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT,
        lazy: () => import('./features/configurations/config-pages/claim'),
        loader: () => dispatch({
          type: GC.VISIT_CONFIG_CLAIM_MANAGEMENT_PAGE,
          payload: GC.ROUTE_PATH_CONFIG_CLAIM_MANAGEMENT,
        }),
      },
      {
        path: GC.ROUTE_PATH_SPLASH_SCREEN_SETTINGS,
        lazy: () => import('./features/styling/components/splash-screen'),
      },
      {
        path: GC.ROUTE_PATH_STYLING,
        lazy: () => import('./features/styling'),
        loader: () => dispatch({
          type: GC.VISIT_STYLING_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_STYLING,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_DASHBOARDS_PAYROLL,
        lazy: () => import('./features/dashboards-payroll'),
        loader: () => dispatch({
          type: GC.VISIT_DASHBOARDS_PAYROLL_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_DASHBOARDS_PAYROLL,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_NOTIFICATIONS,
        lazy: () => import('./features/notification'),
        loader: () => dispatch({
          type: GC.VISIT_NOTIFICATION_LIST_PAGE,
          payload: {
            pathname: GC.ROUTE_PATH_NOTIFICATIONS,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_INTL,
        lazy: () => import('./features/intl'),
        loader: () => dispatch({
          payload: GC.ROUTE_PATH_INTL,
          type: GC.VISIT_TRANSLATION_PAGE,
        }),
      },
      {
        path: GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST,
        lazy: () => import('./features/los-subscription'),
        loader: () => dispatch({
          type: GC.VISIT_ROUTE_LOS_SUBSCRIPTION_PAGE,
          payload: {
            reportType: GC.LOS_SUBSCRIPTION_REPORT,
            pathname: GC.ROUTE_PATH_LOS_SUBCRIPTION_LIST,
          },
        }),
      },
      {
        element: <OAuthPopup />,
        path: GC.ROUTE_PATH_OAUTH2_POPUP,
      },
      {
        path: GC.ROUTE_PATH_WORK_ORDER_LIST,
        lazy: () => import('./features/work-order/report'),
        loader: () => dispatch({
          type: GC.VISIT_WORK_ORDER_LIST_PAGE,
          payload: {
            reportType: GC.WORK_ORDER_REPORT,
            pathname: GC.ROUTE_PATH_WORK_ORDER_LIST,
          },
        }),
      },
      {
        path: GC.ROUTE_PATH_INSPECTIONS,
        lazy: () => import('./features/inspections'),
        loader: () => dispatch({
          type: GC.VISIT_INSPECTION_REPORT_PAGE,
          payload: {
            reportType: GC.INSPECTION_REPORT,
            pathname: GC.ROUTE_PATH_INSPECTIONS,
          },
        }),
      },
      // // Icons and components
      {
        path: GC.ROUTE_PATH_DEV_ICONS,
        lazy: () => import('./features/icons'),
      },
      {
        path: GC.ROUTE_PATH_DEV_COMPONENTS,
        lazy: () => import('./features/components-story'),
      },
    ],
  },
];

const router = (dispatch: Function) => createBrowserRouter(routes2(dispatch));

const Router = () => {
  const dispatch = useDispatch();

  return <RouterProvider router={router(dispatch)} />;
};

export { Router };
